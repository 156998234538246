import React, { useEffect, useState } from "react";
import {
  crossIconWhite,
  crossImg,
  group,
  logoImg,
  navRectangle,
  userImg,
} from "../../images/general";
import { Link, navigate } from "gatsby";
//@ts-ignore
import { useRealmApp } from "../../utils/RealmApp";
import CustomButtton from "../Button/CustomButtton";
import { useAuth } from "../../context/AuthContext";
import { constants } from "../../utils/constants";
import { useUiState } from "../../context/UiContext";
import { BiSolidChevronDown } from "react-icons/bi";
import { OutboundLink } from 'gatsby-plugin-gtag'
import { imxLogout } from "../../integrations/imx";
import { getWindow } from "../../utils";
import { ImxPassport } from "../../integrations/imx/components/ImxPassport";
import AlertBanner from "../../components/AlertBanner";

const Navbar = () => {
  const realmApp: Realm.App = useRealmApp();
  const { currentUser } = realmApp;
  const {
    actions: { setIsLoginModalOpen },
  } = useAuth();
  const {
    state: { isScrolledToStory }, //context to set scroll position for mobile navbar background
  } = useUiState();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const window = getWindow();
  const isMobileDevice = window && window.innerWidth < 768; // Adjust the threshold as needed
  const isHomeScreen = window && window.location.pathname === "/";
  const isShowLogout = currentUser?.isLoggedIn;

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const onLogin = () => {
    if (isMobileDevice) {
      setIsMobileMenuOpen(false);
    }
    setIsLoginModalOpen(true);
  };

  const onLogout = () => {
    currentUser?.logOut().then(() => {
      navigate(constants.app_routes.home);
      imxLogout();
    });
  };

  const isNotShowNavBarInMobile =
    (isMobileDevice && isHomeScreen && !isScrolledToStory) || isMobileMenuOpen;

  const isShowDesktopLoginAlert =
    currentUser?.isLoggedIn &&
    isMobileDevice &&
    window?.location?.pathname?.includes(constants.app_routes.account);

  return (
    <>
      {/**SHOW ALERT BANNER IF MOBILE DEVICE AND LOGGED IN */}
      {isShowDesktopLoginAlert && (
        <AlertBanner
          text="Log in from your PC / MAC device to download and play the game."
          autoHide={true}
        />
      )}
      <div className={`m-auto`}>
        <nav
          style={{
            backgroundImage: `url(${
              isNotShowNavBarInMobile ? "none" : navRectangle
            })`,
          }}
          className="nav-custom fixed z-[100] flex items-center xl:justify-normal justify-between bg-no-repeat bg-fullSize w-full mx-auto px-6 text-white h-auto py-4"
        >
          <div className="flex pl-[1%] justify-between xl:justify-normal w-full">
            <Link
              className="text-3xl font-bold leading-none"
              to={constants.app_routes.home}
            >
              <span className="cursor-pointer">
                {!isNotShowNavBarInMobile && <img src={logoImg} alt="logo" />}
              </span>
            </Link>

          <div className="xl:hidden">
            {/**donot show if menu is already opened */}
            {!isMobileMenuOpen && (
              <button className="navbar-burger flex items-center text-white p-3">
                <svg
                  className="block h-4 w-4 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={toggleMobileMenu}
                >
                  <title>Mobile menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
              </button>
            )}
          </div>
          <ul className="hidden xl:flex flex-row gap-8 ml-8">
            <li className="block mt-4 lg:inline-flex items-center lg:mt-0 relative">
              <Link
                to={constants.app_routes.community}
                className="text-sm font-medium "
              >
                Community
              </Link>
              <div className="border-r border-[#66747E] h-[15px] w-[1px] absolute -right-[0.8rem]  hidden lg:block"></div>
            </li>
            <li className="block mt-4  lg:inline-flex items-center lg:mt-0 relative">
              <div className="text-sm font-medium flex items-center gap-1 cursor-pointer">
                Support
                <BiSolidChevronDown className="text-sm font-medium" />
                <div className="nav-dropdown-content">
                  <OutboundLink
                    href={constants.help_shift}
                    target="_blank"
                    className="font-cinzel uppercase"
                  >
                    FAQ
                  </OutboundLink>
                  <OutboundLink
                    href={constants.discord}
                    target="_blank"
                    className="font-cinzel uppercase"
                  >
                    DISCORD
                  </OutboundLink>
                  <OutboundLink
                    href={constants.telegram}
                    target="_blank"
                    className="font-cinzel uppercase"
                  >
                    TELEGRAM
                  </OutboundLink>
                </div>
              </div>
              <div className="border-r border-[#66747E] h-[15px] w-[1px] absolute hidden -right-[0.8rem] lg:block"></div>
            </li>
            <li className="block mt-4  lg:inline-flex items-center lg:mt-0 relative">
              <div className="text-sm font-medium flex items-center gap-1 cursor-pointer">
                About MEE
                <BiSolidChevronDown className="text-sm font-medium" />
                <div className="nav-dropdown-content">
                  <Link
                    to={constants.app_routes.team}
                    className="font-cinzel uppercase"
                  >
                    Team
                  </Link>
                  <OutboundLink
                    href={constants.whitePaper}
                    target="_blank"
                    className="font-cinzel uppercase"
                  >
                    Whitepaper
                  </OutboundLink>
                  <Link
                    to={constants.app_routes.tokenomics}
                    className="font-cinzel uppercase"
                  >
                    Tokenomics
                  </Link>
                  <Link
                  to={constants.app_routes.news}
                  className="font-cinzel uppercase"
                >
                  News
                </Link>
                </div>
              </div>
              <div className="border-r border-[#66747E] h-[15px] w-[1px] absolute hidden -right-[0.8rem] lg:block"></div>
            </li>
            <li className="block mt-4  lg:inline-flex items-center lg:mt-0 relative">
              <Link
                to={constants.app_routes.marketplace.home}
                className="text-sm font-medium "
              >
                Marketplace
              </Link>
              <div className="border-r border-[#66747E] h-[15px] w-[1px] absolute hidden -right-[0.8rem] lg:block"></div>
            </li>
            <li className="block mt-4  lg:inline-flex items-center lg:mt-0 relative">
              <Link
                to={constants.app_routes.mee_token}
                className="text-sm font-medium "
              >
                MEE Token
              </Link>
              <div className="border-r border-[#66747E] h-[15px] w-[1px] absolute hidden -right-[0.8rem] lg:block"></div>
            </li>
            <li className="block mt-4  lg:inline-flex items-center lg:mt-0 relative">
                <Link
                  to={constants.loyality_platform_link}
                  target="_blank"
                  className="text-sm font-medium "
                >
                  Loyalty Platform
                </Link>
                <div className="border-r border-[#66747E] h-[15px] w-[1px] absolute hidden -right-[0.8rem] lg:block"></div>
            </li>
            {currentUser?.isLoggedIn && (
              <li className="block mt-4  lg:inline-flex items-center lg:mt-0 relative">
                <div className="text-sm font-medium flex items-center gap-1 cursor-pointer">
                  Get Game
                  <BiSolidChevronDown className="text-sm font-medium" />
                  <div className="nav-dropdown-content">
                    <OutboundLink
                      href={constants.mac_client}
                      className="font-cinzel uppercase"
                    >
                      MAC
                    </OutboundLink>
                    <OutboundLink
                      href={constants.windows_client}
                      className="font-cinzel uppercase"
                    >
                      WINDOWS
                    </OutboundLink>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
        {/**Donot show get mee and stake mee butons */}
        {/* <Link className="xl:inline-flex lg:ml-auto lg:mr-4 hidden w-max mb-3 md:mb-0 shadow-customdivshadow ">
        <CustomButtton text="Get $MEE" icon2={group} btnSize="small"/>
        </Link>
        <Link className="xl:inline-flex py-1 px-1 rounded-lg hidden shadow-customdivshadow">
        <CustomButtton text="Stake $MEE" icon2={group} btnType="secondary" btnSize="small"/>
        </Link> */}
        <div className="xl:inline-flex lg:ml-auto lg:mr-[1%] hidden w-max mb-3 md:mb-0 shadow-customdivshadow cursor-pointer gap-5">
          {!currentUser?.isLoggedIn && 
          <div className="-mt-1">
              <ImxPassport isRegister={true} registerLogo={userImg}/>
          </div>}
          <div className="-mt-1 text-sm font-medium flex flex-col items-center relative">
            <CustomButtton
              btnType="cyan"
              text={
                currentUser?.isLoggedIn
                  ? currentUser?.profile?.email || ""
                  : "Login"
              }
              icon1={userImg}
              btnSize="small"
              onClick={() =>
                !currentUser?.isLoggedIn && onLogin()
              }
            />
            {currentUser?.isLoggedIn && 
             <div className="nav-dropdown-content !w-full">
                  <Link
                    to={constants.app_routes.account}
                    className="font-cinzel uppercase"
                  >
                    Account
                  </Link>
                  <Link
                    to={constants.app_routes.marketplace.collections}
                    target="_blank"
                    className="font-cinzel uppercase"
                  >
                    My Collections
                  </Link>
                  <Link
                    to={constants.app_routes.staking_vip}
                    className="font-cinzel uppercase"
                  >
                    VIP Staking
                  </Link>
                  <Link
                    to={""}
                    onClick={onLogout}
                    className="font-cinzel uppercase"
                  >
                    Logout
                  </Link>
                </div>}
          </div>
        </div>
      </nav>
      <div
        className={`navbar-menu relative z-[100] ${
          isMobileMenuOpen ? "" : "hidden"
        }`}
      >
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="fixed top-0 right-0 bottom-0 flex flex-col w-[20rem] max-w-sm py-6 px-6 bg-megamenu overflow-y-auto">
          <div className="flex items-center mb-2 w-[17rem]">
            <Link
              className="mr-auto text-3xl font-bold leading-none"
              to={constants.app_routes.home}
              onClick={toggleMobileMenu}
            >
              <img src={logoImg} alt="logo" />
            </Link>
            <img
              src={crossIconWhite}
              className="navbar-close"
              onClick={toggleMobileMenu}
            />
          </div>
          <div>
            <ul className="">
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.app_routes.community}
                  onClick={toggleMobileMenu}
                  className="text-sm font-medium"
                >
                  Community
                </Link>
              </li>
              {/* <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to="#"
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  Support
                </Link>
              </li> */}
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.app_routes.news}
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  News
                </Link>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <OutboundLink
                  href={constants.whitePaper}
                  target="_blank"
                  className="text-sm font-medium"
                >
                  Whitepaper
                </OutboundLink>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.app_routes.team}
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  Team
                </Link>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.app_routes.tokenomics}
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  Tokenomics
                </Link>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.app_routes.marketplace.home}
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  Marketplace
                </Link>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.app_routes.mee_token}
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  MEE Token
                </Link>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                  <Link
                    to={constants.loyality_platform_link}
                    className="text-sm font-medium"
                    target="_blank"
                  >
                    Loyalty Platform
                  </Link>
              </li>
              <li className="block mt-4 xl:inline-block xl:mt-0 text-white">
                <Link
                  to={constants.help_shift}
                  className="text-sm font-medium"
                  onClick={toggleMobileMenu}
                >
                  Support
                </Link>
              </li>
            </ul>
          </div>
          <div className="">
            <div className="pt-6 w-60">
              {/**Donot show get mee and stake mee butons */}
              {/* <CustomButtton text="Get $MEE" icon2={group} btnSize="small"/>
            <CustomButtton text="Stake $MEE" icon2={group} btnType="secondary" btnSize="small"/> */}
              <div
                className={`flex flex-col gap-5 items-center rounded-lg cursor-pointer text-white hover:scale-105 transition duration-500 ${currentUser?.isLoggedIn && "ml-8"}`}
              >
                <div className="flex flex-col">
                {!currentUser?.isLoggedIn &&
              <ImxPassport isRegister={true}/>}
                <CustomButtton
                  btnType="cyan"
                  text={
                    currentUser?.isLoggedIn
                      ? currentUser?.profile?.email || ""
                      : "Login"
                  }
                  btnSize="small"
                  onClick={() => {
                    currentUser?.isLoggedIn
                      ? navigate(constants.app_routes.account)
                      : onLogin();
                    toggleMobileMenu();
                  }}
                />
                </div>
                <div className="flex justify-center">
                  {isShowLogout && (
                    <CustomButtton
                      btnType="cyan"
                      text="Logout"
                      btnSize="small"
                      onClick={onLogout}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    </>
  );
};

export default Navbar;
