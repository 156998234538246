import { polygon } from "viem/chains";
import { config } from "@imtbl/sdk";
import { immutableZkEvm } from "../integrations/imx/chain";

export const environment = {
    vip_staking_contract_address: "0x927a936AdF429773597a65096eec996bca8d278A",
    mee_token_address: "0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D",
    mee_land_nft_address: "0x895753f33e413655DdAfF8E325d02539631c0510",
    subgraph_url: "https://api.studio.thegraph.com/query/16975/medieval-empires-staking-v2/version/latest",
    mongo_api_base_url: `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.GATSBY_REALM_APP_ID}/endpoint`,
    purchase_usdc_contract_address: "0xCe2e222fA77D30b71081492CdaA57A18CCA2E592",
    purchase_usdt_contract_address: "0x7E1Ef3cE145174f635aDEF900006e64DD98F7f8B",
    purchase_matic_contract_address: "0xd69bA2F0871Fc8460830BfBE50242894818079aD",
    usdc_token_address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    usdt_token_address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    burn_address: "0x000000000000000000000000000000000000dEaD",
    wallet_connect_project_id: "68bb307009604d8ba58c5c39fd3137e0",
    imx_resource_nft_address: "0x2927d7903268a4C3395cE108B069241204A4168a",
    imx_genesis_nft_address: "0xe0087174eb2DECfD54bd4b9358B5f8E670393Cc2",
    imx_land_nft_address: "0xBff2BbE19B1322a87184e45C929dAf65BF020414",
    imx_client_id: "EjgLYCJOIyW2yBNmff21Fpe7HklJwfvK",
    imx_environment: config.Environment.PRODUCTION,
    imx_publishable_key: "pk_imapik-CnSTkaOOFQAIClJzOiyV",
    imx_chain: immutableZkEvm,
    chains: [polygon, immutableZkEvm],
    blockExplorer: "https://polygonscan.com",
    contentful_space_id: `${process.env.GATSBY_CONTENTFUL_SPACE_ID}`,
    contentful_environment: "master",
    contentful_access_token: `${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
    contentful_preview_access_token: `${process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    contentful_preview_host: "preview.contentful.com",
    sphere_link: "https://sphere.market",
    sphere_env: "immutable"
}   
