import React from "react";
//@ts-ignore
import { RealmAppProvider } from "../utils/RealmApp";
import { ParallaxProvider } from "react-scroll-parallax";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";
import ContextProviders from "./ContextProviders";

const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryParamProvider adapter={ReachAdapter}>
      <ContextProviders>
        <ParallaxProvider>
          <RealmAppProvider appId={process.env.GATSBY_REALM_APP_ID}>
            {children}
          </RealmAppProvider>
        </ParallaxProvider>
      </ContextProviders>
    </QueryParamProvider>
  );
};

export default AppProviders;
