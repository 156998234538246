import React, { useEffect, useState } from "react";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { useAuth } from "../../context/AuthContext";
import { useUiState } from "../../context/UiContext";
import Footer from "../Footer";
import LoginModal from "../Modals/LoginModal";
import RegisterModal from "../Modals/RegisterModal";
import ResetModal from "../Modals/ResetModal";
import Navbar from "../Navbar";
import { executeImxLoginCallback, passportInstance } from "../../integrations/imx";
import { useSku } from "../../hooks/useSku";
import { SEO } from "../../components/Seo";
import { useImx } from "../../integrations/imx/hooks/useImx";
import { useUserCustomData } from "../../hooks/useUserCustomData";
import CookieConsent from "../../components/CookieConsent";

const Layout = ({
  children,
  location,
}: {
  children: React.ReactNode;
  location: any;
}) => {

  const { isLoggedIn, refreshCustomData } = useUserCustomData();
  const { getAndSetSkuList } = useSku();
  //to set query params, so user can bookmark it
  const [loginQuery] = useQueryParam("login", BooleanParam);
  const [registerQuery] = useQueryParam("register", BooleanParam);
  const [resetQuery] = useQueryParam("reset", BooleanParam);

  //global loader
  const {
    state: { isLoading, toastState },
  } = useUiState();

  const {
    state: { isLoginModelOpen, isRegisterModelOpen, isResetModelOpen }, //use context state as well, in case state needed
    actions: {
      setIsLoginModalOpen,
      setIsRegisterModalOpen,
      setIsResetModalOpen,
      setReferralCode
    }
  } = useAuth();

  //IMX LOGIN
  const [code] = useQueryParam("code", StringParam);
  const [state] = useQueryParam("state", StringParam);

  useEffect(() => {
    console.log("IMX PASSPORT CODE AND STATE", `${code}, ${state}`);
    if (code !== undefined && state !== undefined && passportInstance) {
      executeImxLoginCallback();
    }
  }, [code, state]);
  //IMX LOGIN
  
  //referral flow
  const { onImxLogin } = useImx();
  const [isReferralSignupInvoked, setIsReferralSignupInvoked] = useState(false);
  const [referralCode] = useQueryParam("referralCode", StringParam);

  const onReferred = async () => {
    if(!referralCode) return;
    setReferralCode(referralCode);
    await onImxLogin();
    setIsReferralSignupInvoked(true);
  }
  //referral flow
  useEffect(() => {
    const init = async () => {
        //referral flow
      if (referralCode && !isReferralSignupInvoked && !isLoggedIn) {
        await onReferred();
      }
        //referral flow
      await getAndSetSkuList();
      await refreshCustomData();
    }
    init();
  }, [isLoggedIn]);

  return (
    <>
      <SEO />
      <Navbar />
      {isLoading && <div id="cover-spin"></div>}
      {toastState.type !== 0 && (
        <div
          className={`toast-container ${
            toastState.type === 1 ? "bg-gray-200" : "bg-red"
          } text-gray-700 text-[1.25rem] px-6 py-4 rounded-lg shadow-lg fixed top-10 mx-3 lg:right-10 lg:mx-0 mt-10 z-[100]`}
        >
          {toastState.msg}
        </div>
      )}
      {children}
      <LoginModal
        isVisible={loginQuery || false}
        location={location}
        onClose={() => setIsLoginModalOpen(false)}
      />
      <RegisterModal
        isVisible={registerQuery || false}
        onClose={() => setIsRegisterModalOpen(false)}
      />
      <ResetModal
        isVisible={resetQuery || false}
        onClose={() => setIsResetModalOpen(false)}
      />
      <CookieConsent/>
      <Footer />
    </>
  );
};

export default Layout;
