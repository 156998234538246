import React, { useEffect, useState } from "react";
//@ts-ignore
import { useRealmApp } from "../utils/RealmApp";
import { useUiState } from "../context/UiContext";
import { useAuth } from "../context/AuthContext";
import { RealmProviderType } from "../types/Auth";
import { ToastType } from "../types/UI";

export const useUserCustomData = (silent = false) => {
  const {
    actions: { setIsLoading, showToast },
  } = useUiState();

  const {
    state: { userCustomData, referralCode },
    actions: { setUserCustomData, setReferralCode },
  } = useAuth();

  const realmApp: Realm.App = useRealmApp();
  const { currentUser } = realmApp;
  const [isCustomDataLoading, setIsCustomDataLoading] = useState(false);

  const refreshAndSetCustomData = async () => {
    if (!silent) setIsLoading(true);
    setIsCustomDataLoading(true);
    try {
      if (currentUser && currentUser?.isLoggedIn) {
        await currentUser?.refreshCustomData();
        const _customData = await currentUser?.functions?.getUserProfile();
        console.log(_customData, "Custom data");
        setUserCustomData(_customData?.result);
        //if user profile created
        const userId = _customData?.result?._id;
        //if referral code present call the function to redeem referral
        if(userId && referralCode) {
          validateReferralCode(referralCode).finally(() => {
            //remove referral code
            setReferralCode(null);
          });
        }
      }
    } catch (e) {
      console.log("Fetching Custom Data Error", e);
    }
    setIsCustomDataLoading(false);
    if (!silent) setIsLoading(false);
  };

  const checkDoesTownModelExist = async () => {
      if (currentUser) {
        const townModelStatus =
          await currentUser?.functions?.doesTownModelExist();
        console.log(townModelStatus, "Town Model Status");
        return townModelStatus;
      }
      return false;
  }

  const saveUserName = async (username: string) => {
    if (userCustomData?.user_name === username) {
      return;
    }
    try {
      setIsLoading(true);
      console.log(userCustomData?.user_name);
      const saveUsernameResp = await currentUser?.functions?.saveUsername(
        username
      );
      if (saveUsernameResp?.error) {
        showToast({ type: ToastType.ERROR, msg: saveUsernameResp?.error });
      }
      await refreshAndSetCustomData();
    } catch (e) {
      console.log("Error while saving the username", e);
    } finally {
      setIsLoading(false);
    }
  };

  const validateReferralCode = async (referralCode: string) => {
    try {
      if(!referralCode) throw new Error("Referral code not found");
      if(!currentUser?.id) throw new Error("User id not found");
      await currentUser?.functions?.validateReferralCode(currentUser?.id, referralCode);
    }
    catch(error) {
      console.log("Error while validating referral code", error);
    }
  }

  const isImxUser = currentUser?.providerType === RealmProviderType.CustomToken;
  const identities =
    userCustomData?.identities ||
    currentUser?.identities?.map((idt) => ({
      ...idt,
      provider_type: idt.providerType,
    })) ||
    [];
  const identityProviders = currentUser
    ? identities.map((idt) => idt.provider_type)
    : [];
  const isLinkedAccounts =
    identityProviders.includes(RealmProviderType.CustomToken) &&
    identityProviders.includes(RealmProviderType.LocalUserpass);

  return {
    refreshCustomData: refreshAndSetCustomData,
    checkDoesTownModelExist,
    saveUserName,
    validateReferralCode,
    isCustomDataLoading,
    isImxUser,
    isLinkedAccounts,
    isLoggedIn: currentUser?.isLoggedIn
  };
};
